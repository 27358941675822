import { ModalEmpresasHunnoContabilComponent } from './modal-empresas-hunno-contabil/modal-empresas-hunno-contabil.component';
import { AppModule } from '../app.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DialogModule} from "primeng/dialog";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { TableModule } from "primeng/table";
import { ModalEmpresasToqwebChaveComponent } from './modal-empresas-toqweb/modal-empresas-toqweb.component';
import {ButtonModule} from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {InputTextModule} from 'primeng/inputtext';

@NgModule({
  declarations: [
        ModalEmpresasToqwebChaveComponent,
        ModalEmpresasHunnoContabilComponent
  ],
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        ReactiveFormsModule,
        TableModule,
        ProgressSpinnerModule,
        ButtonModule,
        InputTextModule,
    ],
    exports: [
        ModalEmpresasToqwebChaveComponent,
        ModalEmpresasHunnoContabilComponent
    ]
})
export class ModaisModule { }
