<p-dialog [modal]="true" [responsive]="true" [contentStyle]="{'min-width':'500px', 'overflow':'visible'}"
    [maximizable]="true" [header]="'Selecionar Empresa ToqWeb'" (onHide)="fecharModal()" [(visible)]="modalVisible">
    <div class="p-col">
        <div class="p-grid p-col-12">
            <div class="card">
                <!-- <div>Pesquisar Empresa</div> -->
                <div class="p-col-12">
                    <div class="p-col-12">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input type="text" style="width: 50vw;" pInputText placeholder="Pesquisar Empresa" [(ngModel)]="filtro" />
                        </span>
                    </div>
                    <p-table [paginator]="true" [rows]="qtdLinhas || 7" [totalRecords]="empresas.length" [value]="empresas" [lazy]="false"
                        styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th [ngStyle]="{width: '70%'}"> Empresa</th>
                                <th [ngStyle]="{width: '10%'}"> </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td>{{rowData.RAZAO_SOCIAL}}</td>
                                <td><button pButton pRipple type="button" label="Acessar" (click)="acessarToqWeb(rowData)"></button></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
