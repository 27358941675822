import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { API_AUTH, USUARIO_STORAGE_KEY, qtdLinhas, TOKEN_STORAGE_KEY, EMPRESA_STORAGE_KEY} from '../../controller/staticValues'
import { NetworkService } from '../../services/network.service'
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-modal-empresas-toqweb',
  templateUrl: './modal-empresas-toqweb.component.html',
  styleUrls: ['./modal-empresas-toqweb.component.css']
})
export class ModalEmpresasToqwebChaveComponent implements OnInit, OnChanges {

    @Input() modalVisible = false;
    @Input() data
    @Output() closeModal = new EventEmitter()
    usuario
    lista
    filtro = ''
    qtdLinhas = qtdLinhas()

    constructor(private networkService: NetworkService) {
    }

    ngOnInit() {
        /*this.usuario = JSON.parse(sessionStorage.getItem(USUARIO_STORAGE_KEY))  
        this.networkService.getSimplesUrl('https://api.toqweb.com.br:2004/implante/hunno/integra/EmpresasFromHunno').subscribe(v => {

        })*/
    }

    fecharModal(e?) {
        console.log('coe');
        this.closeModal.emit(false)
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.usuario = JSON.parse(sessionStorage.getItem(USUARIO_STORAGE_KEY))  
        this.networkService.getSimplesUrl('https://api.toqweb.com.br:2004/implante/hunno/integra/EmpresasFromHunno').subscribe((v: any) => {
            this.lista = v.value;
        })
    }

    get empresas() {
        return this.lista.filter(v => {
            if (v.RAZAO_SOCIAL === null) {
                v.RAZAO_SOCIAL = ''
            }
            if (v.NOME_FANTASIA === null) {
                v.NOME_FANTASIA = ''
            }
            if (v.CPF_CNPJ === null) {
                v.CPF_CNPJ = ''
            }
            return v.RAZAO_SOCIAL.toLowerCase().includes(this.filtro.toLowerCase()) || v.NOME_FANTASIA.toLowerCase().includes(this.filtro.toLowerCase()) || v.CPF_CNPJ.toString().includes(this.filtro)
        })
    }

    acessarToqWeb(event) {
        this.networkService.getSimplesUrl('https://api.toqweb.com.br:2004/implante/hunno/integra/LoginUserFromHunno').subscribe(value => {
            this.networkService.getSimplesUrl('https://api.toqweb.com.br:2004/implante/hunno/integra/LoginFromHunno').subscribe((v: any) => {
            let empresa = event
            let usuario = JSON.parse(sessionStorage.getItem(USUARIO_STORAGE_KEY));
            empresa.URI = empresa.URI.replace(/['/']/g, '+')
            usuario.contractor_photo = usuario.contractor_photo.replace(/['/']/g, '+')
            sessionStorage.setItem(EMPRESA_STORAGE_KEY, JSON.stringify(empresa));
            sessionStorage.setItem(USUARIO_STORAGE_KEY, JSON.stringify(usuario));
            window.location.href = `${environment.urlToqWeb}${v.value}` +
                `/${JSON.stringify(value)}` +
                    `/${sessionStorage.getItem(EMPRESA_STORAGE_KEY)}`;
            })
        })
    }

    chaveCopiada(e: any) {
        alert('Chave Copiada')
    }
}
