import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {DashboardDemoComponent} from './demo/view/dashboarddemo.component';
import {FormLayoutDemoComponent} from './demo/view/formlayoutdemo.component';
import {PanelsDemoComponent} from './demo/view/panelsdemo.component';
import {OverlaysDemoComponent} from './demo/view/overlaysdemo.component';
import {MenusDemoComponent} from './demo/view/menusdemo.component';
import {MessagesDemoComponent} from './demo/view/messagesdemo.component';
import {MiscDemoComponent} from './demo/view/miscdemo.component';
import {EmptyDemoComponent} from './demo/view/emptydemo.component';
import {ChartsDemoComponent} from './demo/view/chartsdemo.component';
import {FileDemoComponent} from './demo/view/filedemo.component';
import {DocumentationComponent} from './demo/view/documentation.component';
import {AppMainComponent} from './app.main.component';
import {InputDemoComponent} from './demo/view/inputdemo.component';
import {ButtonDemoComponent} from './demo/view/buttondemo.component';
import {TableDemoComponent} from './demo/view/tabledemo.component';
import {ListDemoComponent} from './demo/view/listdemo.component';
import {TreeDemoComponent} from './demo/view/treedemo.component';
import {DisplayComponent} from './utilities/display.component';
import {ElevationComponent} from './utilities/elevation.component';
import {FlexboxComponent} from './utilities/flexbox.component';
import {GridComponent} from './utilities/grid.component';
import {IconsComponent} from './utilities/icons.component';
import {WidgetsComponent} from './utilities/widgets.component';
import {SpacingComponent} from './utilities/spacing.component';
import {TypographyComponent} from './utilities/typography.component';
import {TextComponent} from './utilities/text.component';
import {MediaDemoComponent} from './demo/view/mediademo.component';
import { LoginComponent } from './auth/login/login.component';
import { LoginGuard } from './auth/service/login.guard';
import { AuthGuard } from './auth/service/auth-guard';
import { SelecaoEmpresaComponent } from './auth/selecao-empresa/selecao-empresa.component';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
    
    {path: `login`, component: LoginComponent, canActivate: [LoginGuard]},
    {path: `selecao-empresa`, component: SelecaoEmpresaComponent},
    // {path: 'dashboad', component: RelatorioNotasVisaoGeralComponent},
    {                 
        path: '', component: AppMainComponent, canActivate: [AuthGuard],
         children: [
            {path: `home`, component: HomeComponent},
        ]
    },

    // {path: 'balancete', component: BalanceteComponent},

];
// {scrollPositionRestoration: 'enabled'})



@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule,
    ]
})
export class AppRoutingModule {
}
