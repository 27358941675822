import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {AppMainComponent} from './app.main.component';

@Component({
    selector: 'app-topbar',
    templateUrl: `./app.topbar.component.html`,
    styleUrls: ['./app.topbar.component.css']
})
export class AppTopbarComponent implements OnInit {

    items: MenuItem[];

    constructor(public app: AppMainComponent) {}

    ngOnInit(){
        this.items = [
            {
                label: 'File',
                items: [{
                        label: 'New', 
                        icon: 'pi pi-fw pi-plus',
                        items: [
                            {label: 'Project'},
                            {label: 'Other'},
                        ]
                    },
                    {label: 'Open'},
                    {label: 'Quit'}
                ]
            },
            {
                label: 'Edit',
                icon: 'pi pi-fw pi-pencil',
                items: [
                    {label: 'Delete', icon: 'pi pi-fw pi-trash'},
                    {label: 'Refresh', icon: 'pi pi-fw pi-refresh'}
                ]
            }
        ];
    }

}
