import { Util, sub } from '../../controller/Util';
import { getUrlRelatorio } from '../../controller/staticValues';
import { NetworkService } from '../../services/network.service';
import { AppBreadcrumbService } from '../../app.breadcrumb.service';
import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    modalToqWeb = false;
    modalHunnoContabil = false;

    constructor(private networkService: NetworkService) {

    }

    ngOnInit() {

    }
}
