<div class="p-fluid p-grid" style="display: flex;justify-content: center;align-items: center;">
        <div class="p-col-4">
                <button style="height: 30vh;width: 30vw;" pButton type="button" label="Gestor de Processos Contábeis"
                    class="p-button-outlined"></button>
        </div>
        <div class="p-col-4">
            <button style="height: 30vh;width: 30vw;" (click)="modalHunnoContabil = true" pButton type="button" label="Hunno Contábil"
                class="p-button-outlined"></button>
        </div>
        <div class="p-col-4">
            <button style="height: 30vh;width: 30vw;" pButton type="button" label="Hunno - BI"
                class="p-button-outlined"></button>
        </div>
        <div class="p-col-4">
            <button style="height: 30vh;width: 30vw;" (click)="modalToqWeb = true" pButton type="button" label="ToqWeb"
                class="p-button-outlined"></button>
        </div>
        <div class="p-col-4">
            <button style="height: 30vh;width: 30vw;" pButton type="button" label="Hunno DP"
                class="p-button-outlined"></button>
        </div>
        <div class="p-col-4">
            <button style="height: 30vh;width: 30vw;" pButton type="button" label="Hunno - Treinamentos"
                class="p-button-outlined"></button>
        </div>
</div>

<app-modal-empresas-toqweb [modalVisible]="modalToqWeb" (closeModal)="modalToqWeb = false"></app-modal-empresas-toqweb>

<app-modal-empresas-hunno-contabil [modalVisible]="modalHunnoContabil" (closeModal)="modalHunnoContabil = false"></app-modal-empresas-hunno-contabil>
