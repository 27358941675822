<div class="topbar clearfix">
    <div class="topbar-left" routerLink="/home">
        <div class="logo"></div>
    </div>

    <div class="topbar-right">
        
        <ul class="topbar-items animated fadeInDown" [ngClass]="{'topbar-items-visible': app.topbarMenuActive}">
            <nav id="menu">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li><a href="#">Sobre</a></li>
                    <li><a href="#">Serviços</a></li>
                    <li><a href="#">Planos</a></li>
                    <li><a href="#">Blog</a></li>
                    <li><a href="#">Contato</a></li>
                </ul>
            </nav>
        </ul>
    </div>
</div>