<div class="login-body-fixo">
    <div class="card login-panel p-fluid">
        <div class="p-grid">
            <div class="p-col-12">
                <img src="../../../assets/layout/images/logo_novo.png">
                <h1>Bem vindo ao Hunno Ambiente Unico</h1>
            </div>
            <form [formGroup]="form" (submit)="logarUsuario()">
                <div style="margin-left: 20px;" class="p-col-12">
                    <span class="p-float-label">
                        <input type="text" autocomplete="off" placeholder="Email" formControlName="User" pInputText>
                        <!-- <label for="password">Password</label> -->
                    </span>
                </div>
                <div style="margin-left: 20px;" class="p-col-12">
                    <span class="p-float-label">
                        <input type="password" autocomplete="off" placeholder="Senha" formControlName="Password" pInputText>
                        <!-- <label for="password">Password</label> -->
                    </span>
                </div>
                <div style="margin-left: 20px;" class="p-col-12">
                    <button class="button-fixo" pButton type="submit" label="Acessar" style="width: 100%" icon="pi pi-user"></button>
                    <!-- <button class="button-fixo" pButton type="button" label="Contratar" style="width: 100%" icon="pi pi-question-circle"></button> -->
                </div>
            </form>
        </div>
    </div>
</div>